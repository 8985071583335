export default [

  {
    path: '/admin/users',
    name: 'admin-user-list',
    component: () => import('@/views/admin/user/list/UserList.vue'),
    meta: {
      resource: 'admin-user',
      action: 'manage',
    },
  },

];
