import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
} from '@/auth/utils';

import settings from './routes/settings';
import finance from './routes/finance';
import profile from './routes/profile';
import apps from './routes/apps';
import auth from './routes/auth';
import miscellaneous from './routes/miscellaneous';
import permission from './routes/permission';
import school from './routes/school';
import admin from './routes/admin';
import portal from './routes/portal'
import report from './routes/report'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'invoice-list' } },
    ...school,
    ...finance,
    ...profile,
    ...settings,
    ...apps,
    ...auth,
    ...miscellaneous,
    ...permission,
    ...admin,
    ...portal,
    ...report,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (to.name.includes('search-invoice') || to.name.includes('my-invoices') || to.name.includes('tra-cuu')) {
    return next();
  }
  const isLoggedIn = isUserLoggedIn();

  console.log(to);
  if (!canNavigate(to)) {
    console.log('can not navigate');
    // Redirect to login if not logged in
    if (!isLoggedIn || to.name === 'unauthorized') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userData');
      return next({ name: 'auth-login' });
    }

    // If logged in => not authorized
    return next({ name: 'unauthorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    console.log('user logged in');
    next({ name: 'invoice-list' });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
