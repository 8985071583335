export default {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // Endpoints utils
  utilsPricingEndpoint: '/v1/utils/pricing',
  utilsCountriesEndpoint: '/v1/country',
  utilsProvinceEndpoint: '/v1/province',
  utilsDistrictEndpoint: '/v1/district',
  utilsWardEndpoint: '/v1/ward',
  utilsVehicleTypesEndpoint: '/v1/utils/vehicle-types',
  utilsPaymentPeriodsEndpoint: '/v1/utils/payment-periods',
  utilsGendersEndpoint: '/v1/utils/genders',
  utilsUnitsEndpoint: '/v1/unit',
  utilsMeterTypesEndpoint: '/v1/utils/meter_types',
  utilsAbilitiesEndpoint: '/v1/permission',
  utilsCalculateQuantityEndpoint: '/v1/invoice/calculate-quantity-with-date-range',

  // Endpoints
  loginEndpoint: '/v1/auth/login',
  registerEndpoint: '/v1/auth/register',
  refreshEndpoint: '/v1/user/refresh-token',
  logoutEndpoint: '/v1/user/logout',
  forgotPasswordEndpoint: '/v1/auth/forgot-password',
  resetPasswordEndpoint: '/v1/auth/reset-password',
  firebaseTokenEndpoint: '/v1/auth/firebase-token',

  // Permission
  permissionListEndPoint: '/v1/permission',
  permissionGroupListEndPoint: '/v1/permission/group',

  userListByAdminEndpoint: '/v1/admin/users',
  userDetailByAdminEndpoint: '/v1/admin/user',
  updateSubscriptionByAdminEndpoint: '/v1/admin/user/subscription',
  changeUserPasswordByAdminEndPoint: '/v1/admin/user/reset-password',

  // Endpoints apartment
  apartmentCreateEndpoint: '/v1/apartment',
  apartmentUpdateEndpoint: '/v1/apartment',
  apartmentDetailEndpoint: '/v1/apartment',
  apartmentListEndpoint: '/v1/apartment',
  apartmentSelectEndpoint: '/v1/apartment/select',
  apartmentDeleteEndpoint: '/v1/apartment',
  apartmentImportEndpoint: '/v1/apartment/import',
  apartmentExportEndpoint: '/v1/apartment/export',
  apartmentAnalyticsEndpoint: '/v1/apartment/analytics',
  apartmentLayoutEndpoint: '/v1/apartment/layout',
  apartmentCalendarEndpoint: '/v1/apartment/calendar',
  apartmentOccupancyRateAnalyticsEndpoint: '/v1/apartment/analytics-detail',

  // Endpoint room
  roomListEndpoint: '/v1/room',
  roomSelectEndpoint: '/v1/room/select',
  roomCreateEndpoint: '/v1/room',
  roomUpdateEndpoint: '/v1/room',
  roomDetailEndpoint: '/v1/room',
  roomDetailForSaleEndpoint: '/v1/room/for-sale',
  roomDeleteEndpoint: '/v1/room',
  roomExportEndpoint: '/v1/room/export',
  roomAnalyticEndpoint: '/v1/room/analytics',
  roomImportEndpoint: '/v1/room/import',
  roomGenerateImportTemplateEndpoint: '/v1/room/generate-import-template',

  // Endpoint bed
  bedListEndpoint: '/v1/bed',
  bedCreateEndpoint: '/v1/bed',
  bedUpdateEndpoint: '/v1/bed',
  bedDetailEndpoint: '/v1/bed',
  bedDetailForSaleEndpoint: '/v1/bed/for-sale',
  bedDeleteEndpoint: '/v1/bed',
  bedExportEndpoint: '/v1/bed/export',
  bedAnalyticsEndpoint: '/v1/bed/analytics',

  // Endpoint floor
  floorListEndpoint: '/v1/floor',
  floorCreateEndpoint: '/v1/floor',
  floorUpdateEndpoint: '/v1/floor',
  floorDetailEndpoint: '/v1/floor',
  floorDeleteEndpoint: '/v1/floor',

  // Endpoint fee
  feeListEndpoint: '/v1/fee',
  feeCreateEndpoint: '/v1/fee',
  feeUpdateEndpoint: '/v1/fee',
  feeDetailEndpoint: '/v1/fee',
  feeDeleteEndpoint: '/v1/fee',

  // Endpoint fee type
  feeTypeListEndpoint: '/v1/fee-category',
  feeTypeCreateEndpoint: '/v1/fee-category',
  feeTypeDeleteEndpoint: '/v1/fee-category',

  // Endpoint contract
  contractListEndpoint: '/v1/contract',
  contractParamsEndpoint: '/v1/contract/params',
  contractCreateEndpoint: '/v1/contract',
  contractUpdateEndpoint: '/v1/contract',
  contractDetailEndpoint: '/v1/contract',
  contractDeleteEndpoint: '/v1/contract',
  contractExtendEndpoint: '/v1/contract/extend',
  contractMoveEndpoint: '/v1/contract/move',
  contractTransferEndpoint: '/v1/contract/transfer',
  contractLiquidEndpoint: '/v1/contract/liquid',
  contractRecoverEndpoint: '/v1/contract/recover',
  contractHTMLEndpoint: '/v1/contract/html',
  contractSendEmailEndpoint: '/v1/contract/send-email',
  contractSendSigningEmailEndpoint: '/v1/contract/send-signing-request-email',
  contractDownloadPdfEndpoint: '/v1/contract/pdf',
  contractDownloadDocumentsEndpoint: '/v1/contract/download',
  contractImportEndpoint: '/v1/contract/import',
  contractExportEndpoint: '/v1/contract/export',
  contractPreviewEndpoint: '/v1/contract',
  contractGenerateImportTemplateEndpoint: '/v1/contract/generate-import-template',
  contractUpdateLeavingDateEndpoint: '/v1/contract/update-leaving-date',
  contractAnalyticsEndpoint: '/v1/contract/analytics',
  contractAnalyticsDashboardEndpoint: '/v1/contract/analytics-dashboard',
  contractHandoverEndpoint: '/v1/contract/handover',
  contractHandoverLiquidEndpoint: '/v1/contract/handover-liquid',
  contractSigningOTPEndpoint: '/v1/contract/request-signing-otp',
  contractSignEndpoint: '/v1/contract/sign',
  contractCalculateRemainDiscountEndpoint: '/v1/contract/get-remain-discount-by-contract',
  contractFindEndpoint: '/v1/contract/find',
  contractSigningDocumentsEndpoint: '/v1/contract/signing-documents',

  // Endpoint Income Type
  incomeTypeListEndpoint: '/v1/income-type/list',
  incomeTypeCreateEndpoint: '/v1/income-type/create',
  incomeTypeUpdateEndpoint: '/v1/income-type/update',
  incomeTypeDeleteEndpoint: '/v1/income-type/delete',

  // Endpoint Expense Type
  expenseTypeListEndpoint: '/v1/expense-type/list',
  expenseTypeCreateEndpoint: '/v1/expense-type/create',
  expenseTypeUpdateEndpoint: '/v1/expense-type/update',
  expenseTypeDeleteEndpoint: '/v1/expense-type/delete',

  // Endpoint Income Expense Type
  incomeExpenseTypeListEndpoint: '/v1/income-expense-type',
  incomeExpenseTypeCreateEndpoint: '/v1/income-expense-type',
  incomeExpenseTypeUpdateEndpoint: '/v1/income-expense-type',
  incomeExpenseTypeDeleteEndpoint: '/v1/income-expense-type',

  // Endpoint tenant
  tenantListEndpoint: '/v1/tenant',
  tenantCreateEndpoint: '/v1/tenant',
  tenantUpdateEndpoint: '/v1/tenant',
  tenantDetailEndpoint: '/v1/tenant',
  tenantDeleteEndpoint: '/v1/tenant',
  tenantImportEndpoint: '/v1/tenant/import',
  tenantExportEndpoint: '/v1/tenant/export',
  tenantResidenceInformationEndpoint: '/v1/residence-information',
  tenantResidenceInformationPdfEndpoint: '/v1/residence-information/download',
  tenantAnalyticsEndpoint: '/v1/tenant/analytics',
  tenantAnalyticsDashboardEndpoint: '/v1/tenant/analytics-dashboard',

  // Endpoint vehicle
  vehicleListEndpoint: '/v1/vehicle',
  vehicleCreateEndpoint: '/v1/vehicle',
  vehicleUpdateEndpoint: '/v1/vehicle',
  vehicleDetailEndpoint: '/v1/vehicle',
  vehicleDeleteEndpoint: '/v1/vehicle',
  vehicleImportEndpoint: '/v1/vehicle/import',
  vehicleExportEndpoint: '/v1/vehicle/export',
  vehicleGenerateImportTemplateEndpoint: '/v1/vehicle/generate-import-template',

  // report
  reportByServiceType: '/v1/report/report-by-fee-type',
  reportByService: '/v1/report/report-by-fee',

  // Endpoint school
  schoolListEndpoint: '/v1/school',
  schoolCreateEndpoint: '/v1/school',
  schoolUpdateEndpoint: '/v1/school',
  schoolDetailEndpoint: '/v1/school',
  schoolDeleteEndpoint: '/v1/school',
  schoolImportEndpoint: '/v1/school/import',
  schoolExportEndpoint: '/v1/school/export',
  schoolGenerateImportTemplateEndpoint: '/v1/school/generate-import-template',

  // Endpoint class
  classListEndpoint: '/v1/class',
  classCreateEndpoint: '/v1/class',
  classUpdateEndpoint: '/v1/class',
  classDetailEndpoint: '/v1/class',
  classDeleteEndpoint: '/v1/class',
  classImportEndpoint: '/v1/class/import',
  classExportEndpoint: '/v1/class/export',
  classGenerateImportTemplateEndpoint: '/v1/class/generate-import-template',

  // Endpoint student
  studentListEndpoint: '/v1/student',
  studentCreateEndpoint: '/v1/student',
  studentUpdateEndpoint: '/v1/student',
  studentDetailEndpoint: '/v1/student',
  studentDeleteEndpoint: '/v1/student',
  studentImportEndpoint: '/v1/student/import',
  studentExportEndpoint: '/v1/student/export',
  studentGenerateImportTemplateEndpoint: '/v1/student/generate-import-template',

  // Endpoint reservation
  reservationListEndpoint: '/v1/reservation',
  reservationCreateEndpoint: '/v1/reservation',
  reservationUpdateEndpoint: '/v1/reservation',
  reservationDetailEndpoint: '/v1/reservation',
  reservationDeleteEndpoint: '/v1/reservation',
  reservationExportEndpoint: '/v1/reservation/export',
  reservationAnalyticsEndpoint: '/v1/reservation/analytics',
  reservationGetActiveEndpoint: '/v1/reservation/active',
  reservationTerminateEndpoint: '/v1/reservation/terminate',
  reservationPreviewEndpoint: '/v1/reservation',

  // Endpoint cashbook
  cashbookListEndpoint: '/v1/cashbook',
  cashbookDetailEndpoint: '/v1/cashbook',
  cashbookCreateEndpoint: '/v1/cashbook',
  cashbookUpdateEndpoint: '/v1/cashbook',
  cashbookDeleteEndpoint: '/v1/cashbook',

  // Endpoint provider
  providerListEndpoint: '/v1/provider',
  providerDetailEndpoint: '/v1/provider',
  providerCreateEndpoint: '/v1/provider',
  providerUpdateEndpoint: '/v1/provider',
  providerDeleteEndpoint: '/v1/provider',

  // Endpoint warehouse
  warehouseListEndpoint: '/v1/warehouse',
  warehouseDetailEndpoint: '/v1/warehouse',
  warehouseCreateEndpoint: '/v1/warehouse',
  warehouseUpdateEndpoint: '/v1/warehouse',
  warehouseDeleteEndpoint: '/v1/warehouse',

  // Endpoint asset type
  assetTypeListEndpoint: '/v1/asset-type',
  assetTypeDetailEndpoint: '/v1/asset-type',
  assetTypeCreateEndpoint: '/v1/asset-type',
  assetTypeUpdateEndpoint: '/v1/asset-type',
  assetTypeDeleteEndpoint: '/v1/asset-type',
  assetTypeExportEndpoint: '/v1/asset-type/export',

  // Endpoint asset
  assetListEndpoint: '/v1/asset',
  assetLogListEndpoint: '/v1/asset-log',
  assetDetailEndpoint: '/v1/asset',
  assetCreateEndpoint: '/v1/asset',
  assetUpdateEndpoint: '/v1/asset',
  assetDeleteEndpoint: '/v1/asset',
  assetMoveEndpoint: '/v1/asset/move',
  assetImportEndpoint: '/v1/asset/import',
  assetExportEndpoint: '/v1/asset/export',
  assetGenerateImportTemplateEndpoint: '/v1/asset/generate-import-template',
  assetAnalyticsEndpoint: '/v1/asset/analytics',

  // Endpoint invoice
  invoiceListEndpoint: '/v1/invoice',
  invoiceCreateEndpoint: '/v1/invoice',
  invoiceUpdateEndpoint: '/v1/invoice',
  invoiceUpdateTimeEndpoint: '/v1/invoice/update-time',
  invoiceDeleteEndpoint: '/v1/invoice',
  invoiceDetailEndpoint: '/v1/invoice',
  invoiceGetIncomeExpenseEndpoint: '/v1/invoice/income-expenses',
  invoiceApproveEndpoint: '/v1/invoice/approve',
  invoiceAnalyticsEndpoint: '/v1/invoice/analytics',
  invoiceCreatePaymentEndpoint: '/v1/invoice/create-payment',
  invoiceExportEndpoint: '/v1/invoice/export',
  invoiceExportZipEndpoint: '/v1/invoice/export-zip',
  invoiceExportZipPdfEndpoint: '/v1/invoice/export-zip-pdf',
  invoiceImportEndpoint: '/v1/invoice/import',
  invoiceHtmlEndpoint: '/v1/invoice/html',
  invoicePreviewEndpoint: '/v1/invoice/preview',
  invoicePreviewWithUUIDEndpoint: '/v1/invoice/preview-with-uuid',
  invoicePdfEndpoint: '/v1/invoice/pdf',
  invoiceViettelDraft: '/v1/viettel-invoice/draft',
  invoiceViettelIssue: '/v1/viettel-invoice/issue',
  invoiceImageEndpoint: '/v1/invoice/image',
  invoiceSearch: '/v1/invoice/search',
  invoiceSendViaZaloZNSEndpoint: '/v1/invoice/share-zalo-zns',
  invoiceSendViaZaloNormalEndpoint: '/v1/invoice/send-notifiy-via-zalo',
  invoiceSendViaAppEndpoint: '/v1/invoice/share-via-app',
  invoiceSendViaEmailEndpoint: '/v1/invoice/share-email',
  invoiceSendEndpoint: '/v1/invoice/send',
  invoiceGenerateMultipleEndpoint: '/v1/invoice/generate-multiple',
  invoiceGenerateImportTemplateEndpoint: '/v1/invoice/generate-import-template',
  invoiceParamsEndpoint: '/v1/invoice/params',

  remainPrepaidByStudent: '/v1/prepaid/remain-by-student',

  // Endpoint income
  incomeListEndpoint: '/v1/income/list',
  incomeCreateEndpoint: '/v1/income/create',
  incomeUpdateEndpoint: '/v1/income/update',
  incomeDeleteEndpoint: '/v1/income/delete',

  // Endpoint expense
  expenseListEndpoint: '/v1/expense/list',
  expenseCreateEndpoint: '/v1/expense/create',
  expenseUpdateEndpoint: '/v1/expense/update',
  expenseDeleteEndpoint: '/v1/expense/delete',

  // Endpoint income expense
  incomeExpenseListEndpoint: '/v1/income-expense',
  incomeExpenseApproveEndpoint: '/v1/income-expense/approve',
  incomeExpenseCreateEndpoint: '/v1/income-expense',
  incomeExpenseUpdateEndpoint: '/v1/income-expense',
  incomeExpenseDeleteEndpoint: '/v1/income-expense',
  incomeExpenseAnalyticsEndpoint: '/v1/income-expense/analytics',
  incomeExpenseHTMLEndpoint: '/v1/income-expense/html',
  incomeExpenseDetailEndpoint: '/v1/income-expense',
  incomeExpenseExportEndpoint: '/v1/income-expense/export',
  incomeExpenseImportEndpoint: '/v1/income-expense/import',
  incomeExpenseGenerateImportTemplateEndpoint: '/v1/income-expense/generate-import-template',
  incomeExpenseCalculateAllocationEndpoint: '/v1/income-expense/calculate-allocation',
  incomeExpenseLinkInvoiceEndpoint: '/v1/income-expense/link-invoice',

  // Endpoint role
  roleListEndpoint: '/v1/role',
  roleDetailEndpoint: '/v1/role',
  roleAbilitiesEndpoint: '/v1/role/abilities',
  roleCreateEndpoint: '/v1/role',
  roleUpdateEndpoint: '/v1/role',
  roleDeleteEndpoint: '/v1/role',

  // Endpoint manager
  managerListEndpoint: '/v1/manager',
  managerDetailEndpoint: '/v1/manager',
  managerCreateEndpoint: '/v1/manager',
  managerUpdateEndpoint: '/v1/manager',
  managerDeleteEndpoint: '/v1/manager',

  // Endpoint quota
  quotaListEndpoint: '/v1/quota',
  quotaCreateEndpoint: '/v1/quota',
  quotaUpdateEndpoint: '/v1/quota',
  quotaDeleteEndpoint: '/v1/quota',
  quotaDetailEndpoint: '/v1/quota',

  // Endpoint task
  taskListEndpoint: '/v1/task',
  taskCreateEndpoint: '/v1/task',
  taskUpdateEndpoint: '/v1/task',
  taskUpdateStatusEndpoint: '/v1/task/update-status',
  taskDeleteEndpoint: '/v1/task',
  taskCommentEndpoint: '/v1/task/comment',
  taskExportEndpoint: '/v1/task/export',
  taskAnalyticsEndpoint: '/v1/task/analytics',
  taskDetailEndpoint: '/v1/task',
  taskAnalyticsDashboardEndpoint: '/v1/task/analytics-dashboard',

  // Endpoint meter
  meterListEndpoint: '/v1/meter',
  meterDetailEndpoint: '/v1/meter',
  meterCreateEndpoint: '/v1/meter',
  meterUpdateEndpoint: '/v1/meter',
  meterDeleteEndpoint: '/v1/meter',

  // Endpoint notification
  notificationListEndpoint: '/v1/notification',
  notificationDetailEndpoint: '/v1/notification',
  notificationCreateEndpoint: '/v1/notification',
  notificationUpdateEndpoint: '/v1/notification',
  notificationDeleteEndpoint: '/v1/notification',

  // Endpoint system notification
  systemNotificationListEndpoint: '/v1/system-notification',
  systemNotificationMarkAsReadListEndpoint: '/v1/system-notification/mark-as-read',
  systemNotificationCountUnreadListEndpoint: '/v1/system-notification/count-unread',

  // Endpoint meter log
  meterLogListEndpoint: '/v1/meter-log',
  meterLogApproveEndpoint: '/v1/meter-log/approve',
  meterLogDetailEndpoint: '/v1/meter-log',
  meterLogCreateEndpoint: '/v1/meter-log',
  meterLogUpdateEndpoint: '/v1/meter-log/update',
  meterLogDeleteEndpoint: '/v1/meter-log',
  meterLogExportEndpoint: '/v1/meter-log/export',
  meterLogAnalyticsEndpoint: '/v1/meter-log/analytics',
  meterLogGenerateImportTemplateEndpoint: '/v1/meter-log/generate-import-template',
  meterLogImportEndpoint: '/v1/meter-log/import',

  // Endpoint Chat
  chatListChatAndContactEndpoint: '/v1/chat/list',
  chatDetailEndpoint: '/v1/chat/detail',
  chatProfileEndpoint: '/v1/chat/profile',
  chatSendMessageEndpoint: '/v1/chat/send-message',

  // conversation
  conversationListEndpoint: '/v1/conversation',
  conversationDetailEndpoint: '/v1/conversation',
  conversationMessagesEndpoint: '/v1/conversation/messages',
  conversationCreateEndpoint: '/v1/conversation',

  // conversation
  messageListEndpoint: '/v1/message',
  messageSendEndpoint: '/v1/message',

  // Endpoint user
  userProfileEndpoint: '/v1/user/me',
  userProfileUpdateEndpoint: '/v1/user/update',
  userChangePasswordEndpoint: '/v1/user/change-password',
  userVerifyEmailEndpoint: '/v1/user/verify-email',
  userRequestVerifyEmailEndpoint: '/v1/user/request-verify-email',
  userRequestMissCallOTPEndpoint: '/v1/user/request-miss-call-otp',
  userRequestVerifyPhoneOTPEndpoint: '/v1/user/request-verify-phone',
  userResetPasswordWithOTPEndpoint: '/v1/user/reset-password-with-otp',
  userVerifyPhoneEndpoint: '/v1/user/verify-phone',
  userACLEndpoint: '/v1/user/acl',

  // Endpoint dashboard
  dashboardEndpoint: '/v1/dashboard/get-data',

  // Enpoint configuration
  userConfigurationEndpoint: '/v1/user-configuration',
  userConfigurationUpdateEndpoint: '/v1/user-configuration',
  emailConfigurationEndpoint: '/v1/email-configuration',
  emailConfigurationUpdateEndpoint: '/v1/email-configuration',
  apartmentConfigurationEndpoint: '/v1/apartment-configuration',
  apartmentConfigurationUpdateEndpoint: '/v1/apartment-configuration',

  // Report

  reportPropertyGeneral: '/v1/report/property/general',
  reportPropertyCounting: '/v1/report/property/counting',
  reportPropertyDetail: '/v1/report/property/detail',
  reportFinanceByYear: '/v1/report/finance/chart-by-year',
  reportFinanceCashFlow: '/v1/report/finance/cashflow',
  reportFinanceCounting: '/v1/report/finance/counting',
  reportFinanceDeposit: '/v1/report/finance/deposit',
  reportFinanceDepositExport: '/v1/report/finance/deposit/export',
  reportBillingCalendarExport: '/v1/report/finance/billing-calendar/export',
  reportFinancePrepaid: '/v1/report/finance/prepaid',
  reportFinanceByMonth: '/v1/income-expense-allocation',
  reportAnalyticsFinanceByMonth: '/v1/income-expense-allocation/analytics',
  reportFinanceByMonthExport: '/v1/income-expense-allocation/export',

  // report property
  reportPropertyGeneralData: '/v1/report/property/general-data',
  reportPropertySlotsData: '/v1/report/property/slots-data',
  reportPropertyEmptySlots: '/v1/report/property/empty-slots',
  reportPropertyEmptyExportSlots: '/v1/report/property/empty-slots-export',
  reportPropertyNearEmptySlots: '/v1/report/property/near-empty-slots',
  reportPropertyNearEmptySlotsExport: '/v1/report/property/near-empty-slots-export',

  // report tenant
  reportTenantGeneralData: '/v1/report/tenant/general-data',

  // report contract
  reportContractGeneralData: '/v1/report/contract/general-data',

  // report task
  reportTaskGeneralData: '/v1/report/task/general-data',

  // report finance
  reportFinanceIncomeThisMonth: '/v1/report/finance/income-this-month',
  reportFinanceExpenseThisMonth: '/v1/report/finance/expense-this-month',
  reportFinanceIncomeAllocationThisMonth: '/v1/report/finance/income-allocation-this-month',
  reportFinanceExpenseAllocationThisMonth: '/v1/report/finance/expense-allocation-this-month',
  reportIncomeExpenseChartByYear: '/v1/report/finance/income-expense-chart-data',
  reportIncomeExpenseHTMLByYear: '/v1/report/finance/income-expense-by-year-html',
  reportIncomeExpenseHTMLByType: '/v1/report/finance/income-expense-by-type',
  reportFinanceDebt: '/v1/report/finance/debt',
  reportFinanceBillingCalendar: '/v1/report/finance/billing-calendar',
  reportFinanceDebtExport: '/v1/report/finance/debt/export',

  // Prepaid notification
  prepaidListEndpoint: '/v1/prepaid',
  prepaidExportEndpoint: '/v1/prepaid/export',
  prepaidCreateEndpoint: '/v1/prepaid',
  prepaidUpdateEndpoint: '/v1/prepaid',
  prepaidDeleteEndpoint: '/v1/prepaid',
  prepaidDetailByContractEndpoint: '/v1/prepaid/student',
  prepaidRemainByContractEndpoint: '/v1/prepaid/remain-by-contract',

  // Payment
  paymentCreatePaymentUrl: '/v1/payment/create-payment-url',
  paymentValidateResultUrl: '/v1/payment/validate-result',

  // Plan
  planListEndpoint: '/v1/plan',
  planDetailEndpoint: '/v1/plan',

  // Order
  orderListEndpoint: '/v1/order',
  orderCreateEndpoint: '/v1/order',
  orderUpdateEndpoint: '/v1/order',
  orderDeleteEndpoint: '/v1/order',

  // Endpoint Expense Type
  taskTypeListEndpoint: '/v1/task-type',
  taskTypeCreateEndpoint: '/v1/task-type',
  taskTypeUpdateEndpoint: '/v1/task-type',
  taskTypeDeleteEndpoint: '/v1/task-type',

  // Endpoint Expense Type
  signatureListEndpoint: '/v1/signature',
  signatureCreateEndpoint: '/v1/signature',
  signatureUpdateEndpoint: '/v1/signature',
  signatureDeleteEndpoint: '/v1/signature',

  // admin
  adminListHost: '/v1/admin/hosts',
  adminListHostAnalytics: '/v1/admin/host-analytics',
  adminHostDetail: '/v1/admin/host',
  adminHostSubscription: '/v1/admin/host/subscription',
  adminHostSyncDataFromOldSystem: '/v1/admin/host/sync-data-from-old-system',
  adminHostChangePassword: '/v1/admin/host/reset-password',
  adminListOrder: '/v1/admin/orders',
  adminListOrderAnalytics: '/v1/admin/order-analytics',
  adminOrderDetail: '/v1/admin/order',
  adminListTenant: '/v1/admin/tenants',
  adminListTenantAnalytics: '/v1/admin/tenant-analytics',
  adminTenantDetail: '/v1/admin/tenant',

  // upload attachment
  attachmentUploadEndpoint: "/v1/attachment/upload",
  attachmentDeleteEndpoint: "/v1/attachment",

  // job
  jobEndPoint: "/v1/job",
  jobReRunEndPoint: "/v1/job/re-run",

  // sale
  salePathDataEndPoint: "/v1/sale",

  // system log
  systemLogEndPoint: "/v1/system-log",

  // Banner
  bannerListEndpoint: '/v1/banner',
  bannerCreateEndpoint: '/v1/banner',
  bannerUpdateEndpoint: '/v1/banner',
  bannerDeleteEndpoint: '/v1/banner',

  // Endpoint location
  locationListEndpoint: '/v1/location',
  locationCreateEndpoint: '/v1/location',
  locationUpdateEndpoint: '/v1/location',
  locationDetailEndpoint: '/v1/location',
  locationDeleteEndpoint: '/v1/location',

  // Endpoint hotline
  hotlineListEndpoint: '/v1/hotline',
  hotlineCreateEndpoint: '/v1/hotline',
  hotlineUpdateEndpoint: '/v1/hotline',
  hotlineDetailEndpoint: '/v1/hotline',
  hotlineDeleteEndpoint: '/v1/hotline',

  // bank
  bankListEndpoint: '/v1/bank',
  bankListTingeeEndpoint: '/v1/bank/tingee',

  // Endpoint deposit template
  depositTemplateListEndpoint: '/v1/deposit-template',
  depositTemplateCreateEndpoint: '/v1/deposit-template',
  depositTemplateUpdateEndpoint: '/v1/deposit-template',
  depositTemplatePreviewEndpoint: '/v1/deposit-template',
  depositTemplateDetailEndpoint: '/v1/deposit-template',
  depositTemplateDeleteEndpoint: '/v1/deposit-template',

  // Endpoint contract template
  contractTemplateListEndpoint: '/v1/contract-template',
  contractTemplateCreateEndpoint: '/v1/contract-template',
  contractTemplateUpdateEndpoint: '/v1/contract-template',
  contractTemplatePreviewEndpoint: '/v1/contract-template',
  contractTemplateDetailEndpoint: '/v1/contract-template',
  contractTemplateDeleteEndpoint: '/v1/contract-template',

  // Endpoint handover template
  handoverTemplateListEndpoint: '/v1/handover-template',
  handoverTemplateCreateEndpoint: '/v1/handover-template',
  handoverTemplateUpdateEndpoint: '/v1/handover-template',
  handoverTemplatePreviewEndpoint: '/v1/handover-template',
  handoverTemplateDetailEndpoint: '/v1/handover-template',
  handoverTemplateDeleteEndpoint: '/v1/handover-template',

  // Endpoint invoice template
  invoiceTemplateListEndpoint: '/v1/template',
  invoiceTemplateCreateEndpoint: '/v1/template',
  invoiceTemplateUpdateEndpoint: '/v1/template',
  invoiceTemplatePreviewEndpoint: '/v1/template',
  invoiceTemplateDetailEndpoint: '/v1/template',
  invoiceTemplateDeleteEndpoint: '/v1/template',

  // Endpoint smart device
  smartDeviceListEndpoint: '/v1/smart-device',
  smartDeviceDetailEndpoint: '/v1/smart-device',
  smartDeviceCreateEndpoint: '/v1/smart-device',
  smartDeviceUpdateEndpoint: '/v1/smart-device',
  smartDeviceDeleteEndpoint: '/v1/smart-device',
  smartDeviceSyncVConnexWithCodeEndpoint: '/v1/smart-device/vconnex/sync-with-code',
  smartDeviceSyncVConnexAuthEndpoint: '/v1/smart-device/vconnex/auth-url',
  smartDeviceLinkEndpoint: '/v1/smart-device/link-devices',
  smartDeviceGetTuyaAccountEndpoint: '/v1/smart-device/register-tuya-account',
  smartDeviceSyncTuyaDevicesEndpoint: '/v1/smart-device/sync-tuya-devices',
  smartDeviceChangeStatusEndpoint: '/v1/smart-device/set-enabling-switch',

  // tingee
  tingeeVerifyOCBInfoEndpoint: '/v1/tingee/verify-ocb-info',
  tingeeConnectBankAccountEndpoint: '/v1/tingee/connect-bank-account',
  tingeeVerifyBankAccountEndpoint: '/v1/tingee/verify-bank-account',
  tingeeVerifyOCBInfoWithOtpEndpoint: '/v1/tingee/verify-ocb-info-with-otp',
  tingeeOCBAccountEndpoint: '/v1/tingee/ocb-account',
  tingeeOCBCreateVAAccountEndpoint: '/v1/tingee/ocb/create-va-coporation',
  tingeeDeleteAccountEndpoint: '/v1/tingee',
  // other handover tingtong
  otherHandoverTingTongDefaultEndpoint: '/v1/other-handover-tingtong/default',
  otherHandoverTingTongByContractEndpoint: '/v1/other-handover-tingtong/get-by-contract',
};
