export default [

  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/apps/job/list/JobList.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
  {
    path: '/system-logs',
    name: 'system-logs',
    component: () => import('@/views/apps/system-log/list/SystemLogList.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
];
