export default [
  {
    path: '/report/report-by-service-type',
    name: 'report-by-service-type',
    component: () => import('@/views/report/report-by-service-type/ReportByServiceType.vue'),
    meta: {
      resource: 'report-by-service-type',
      action: 'read',
    },
  },

];
