export default [
  {
    path: '/search-invoice',
    name: 'search-invoice',
    component: () => import('@/views/portal/search/SearchInvoice.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/tra-cuu',
    name: 'tra-cuu',
    component: () => import('@/views/portal/search/SearchInvoice.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/my-invoices',
    name: 'my-invoices',
    component: () => import('@/views/portal/invoices/MyInvoice.vue'),
    meta: {
      layout: 'full',
    },
  },
];
